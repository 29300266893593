import axios from "axios";

import { authorizedReq, BASE_API_URL } from "./index";

export const getUserDetails = () =>
  authorizedReq.get(BASE_API_URL + "/user/details");

export const getMerchantConfig = () =>
  axios.get(BASE_API_URL + "/config/", {
    params: { json: true },
  });
