function checkPath(location) {
  let flag = false;
  const paths = [
    "/pos",
    "/new-menu",
    "/catalogue",
    "/events",
    "/checkout",
    "/spf",
  ];
  flag = paths.some((url) => location.includes(url));

  if (location.includes("transaction") || location.includes("table-header")) {
    flag = false;
  }

  return flag;
}

export { checkPath };
